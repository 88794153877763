import React from "react";
import GridSpinner from "./GridSpinner";

const FullPageSpinner = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <GridSpinner />
    </div>
  );
};

export default FullPageSpinner;
