import { useState, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { colors } from "../utils/themeColors";

const GridSpinner = () => {
  return (
    <GridLoader
      color={colors.emerald}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default GridSpinner;
