import { Routes, Route, useMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import ErrorPage from "./sharedComponents/ErrorPage";
import Profile from "./features/user/Pages/Profile";
import React, { Suspense, lazy, useRef } from "react";
import FullPageSpinner from "./sharedComponents/FullPageSpinner";
import DashboardsGrid from "./features/dashboards/DashboardsGrid";
import { MenuItem } from "./layoutComponents/types";
import VerticalNavBar from "./layoutComponents/VerticalNavBar";
import HorizontalNavBar from "./layoutComponents/HorizontalNavBar";

const DashboardViewer = lazy(
  () => import("./features/dashboardViewer/DashboardViewer")
);

function AppShell() {
  const topMenuItems: MenuItem[] = [
    {
      iconName: "DASHBOARDS_FOLDER",
      title: "Dashboards",
      url: "/",
    },
    {
      iconName: "USERS",
      title: "Users",
      url: "/users",
    },
    {
      iconName: "CONNECTIONS",
      title: "Connections",
      url: "/connections",
    },
    {
      iconName: "ADMINISTRATION",
      title: "Administration",
      url: "/admin",
    },
  ];

  const bottomMenuItems: MenuItem[] = [
    {
      iconName: "DOWNLOAD",
      title: "Download",
      url: "/download",
    },
    {
      iconName: "SUBSCRIPTION",
      title: "Subscription",
      url: "/subscription",
    },
    {
      iconName: "HELP",
      title: "Help",
      url: "/help",
    },
  ];
  
  const verticalNavBarRef = useRef(null);
  const horizontalNavBarRef = useRef(null);
  const mainContentRef = useRef(null);

  const noTopBar = useMatch("/dashboardView");

  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className="flex bg-gray-50 min-h-screen">
      <CSSTransition
        nodeRef={verticalNavBarRef}
        timeout={500}
        classNames="verticalNavBar"
        in={isMounted}
        appear
      >
        <div className="fixed" ref={verticalNavBarRef}>
          <VerticalNavBar
            topMenuItems={topMenuItems}
            bottomMenuItems={bottomMenuItems}
          />
        </div>
      </CSSTransition>
      <div className="w-full ml-[80px]">
        {!noTopBar && (
          <CSSTransition
            nodeRef={horizontalNavBarRef}
            timeout={1000}
            classNames="horizontalNavBar"
            in={isMounted}
            appear
          >
            <div ref={horizontalNavBarRef}>
              <HorizontalNavBar />
            </div>
          </CSSTransition>
        )}
        <CSSTransition
          nodeRef={mainContentRef}
          timeout={1000}
          classNames="mainContent"
          in={isMounted}
          appear
        >
          <div
            id="main-content"
            ref={mainContentRef}
            className="flex justify-center "
          >
            <Routes>
              <Route path="/" element={<DashboardsGrid />} />
              <Route path="/users" element={<ErrorPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/connections" element={<ErrorPage />} />

              <Route
                path="/dashboardView"
                element={
                  <Suspense fallback={<FullPageSpinner />}>
                    <DashboardViewer />
                  </Suspense>
                }
              />
              <Route path="/statistics" element={<ErrorPage />} />
              <Route path="/admin" element={<ErrorPage />} />
              <Route path="/download" element={<ErrorPage />} />
              <Route path="/subscription" element={<ErrorPage />} />
              <Route path="/help" element={<ErrorPage />} />
            </Routes>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default AppShell;
