import { useContext } from "react";
import { User } from "../../../model";
import UserAuthContext from "./UserAuthContext";

export const useGetLoggedUser = (): User => {
  const user: User = useContext(UserAuthContext).user;
  if (user === undefined) {
    throw new Error("useAuthContext can only be used inside AuthProvider");
  }
  return user;
};
