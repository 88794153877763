import React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import useDashboards from "./hooks/useDashboards";
import { useGetLoggedUser } from "../auth/hooks/useGetLoggedUser";
import { Dashboard, PatchDashboardsParams } from "../../model";
import HeartPin from "./HeartPin";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useDashboardPinMutation from "./hooks/useMutation";
import DashboardRowActions from "./DashboardRowActions";

function DashboardsGrid() {
  const user = useGetLoggedUser();
  const dashboards = useDashboards(user.email ?? "");
  const queryClient = useQueryClient();

  const dashboardPinMutation = useDashboardPinMutation(
    queryClient,
    user.email ?? ""
  );

  const updateDashboardPinned = (dashboard: Dashboard, isPinned: boolean) => {
    const dashboardParams: PatchDashboardsParams = {
      spotID: dashboard.spotID!,
      isFavorite: isPinned,
      userID: user.userID!,
    };
    dashboardPinMutation.mutate(dashboardParams);
  };

  function GetDashboardImgUrl(spotID: number): string {
    //const imgUrl = `/images/grabs/mini/${spotID}-mini.png`;
    //Generate a randome number between 1 and 4
    const imgUrl = `/images/grabs/mini/${spotID}-mini.png`;
    return imgUrl;
  }

  const columnHelper = createColumnHelper<Dashboard>();
  const columns = [
    columnHelper.accessor("grab", {
      header: () => "",
      size: 150,
      minSize: 150,
      maxSize: 150,
      cell: ({ getValue, row, column: { id }, table }) => {
        const dashboard = row.original;
        return (
          <div className="pl-4">
            <img
              alt={dashboard.title!}
              className="w-12 rounded-full border border-gray-100"
              src={GetDashboardImgUrl(dashboard.spotID! % 4)}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.title, {
      id: "Title",
      cell: (info) => <Link to="/dashboardView">{info.getValue()}</Link>,
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor("category", {
      header: () => "Category",
      cell: (info) =>
        info.cell.getValue() ? (
          <span className="bg-darkBlue py-0.5 px-3 rounded-full text-white text-xs">
            {info.renderValue()}
          </span>
        ) : (
          ""
        ),
    }),
    columnHelper.accessor("saveDate", {
      header: () => "Last Update",
      cell: (info) => <span className="text-sm">{info.renderValue()}</span>,
    }),

    columnHelper.accessor("isPinned", {
      header: () => "",
      cell: ({ getValue, row, column: { id }, table }) => {
        const isPinned = getValue() ?? false;
        return (
          <HeartPin
            isPinned={isPinned}
            handlePinClick={() =>
              updateDashboardPinned(row.original, !isPinned)
            }
          />
        );
      },
    }),
    columnHelper.accessor("owner", {
      header: () => <span className="">Owner</span>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => "Actions",
      cell: (props) => <DashboardRowActions row={props.row} />,
    }),
  ];

  const table = useReactTable({
    data: dashboards,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full max-w-[1200px] m-10 border-separate border-spacing-y-1 border-spacing-x-0">
      <thead className="h-12">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="relative top-4 font-medium text-emerald text-sm text-left uppercase  tracking-tight"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className=" text-darkBlue ">
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="h-20 bg-white hover:shadow-md transition-transform cursor-pointer"
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  key={cell.id}
                  className={`border-t border-b  border-gray-100 font-medium ${
                    cell.column.id === "grab" && "border-l"
                  } ${cell.column.id === "actions" && "border-r"}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DashboardsGrid;
