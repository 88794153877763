// ./api/axios.ts

import Axios, { AxiosRequestConfig } from "axios";

// our global Axios instance including the base URL
const axios = Axios.create({
  //Get env variable from .env file
  baseURL: process.env.REACT_APP_API_SERVER_URL,
});

// this function was taken from the Orval docs
export default async function customInstance<T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> {
  const { data } = await axios({ ...config, ...options });
  return data;
}
