import React, { PropsWithChildren } from "react";
import { AUTH_ANIMATION_DURATION, OK_ANIMATION_DURATION } from "../AuthStateMachine";
import "./animationsStyleBtn.css";

type SubmitButtonStatus =
  | "Idle"
  | "AuthoringAnimation"
  | "WaitingAnimation"
  | "OKAnimation"
  | "AuthKO";

interface Props {
  status: SubmitButtonStatus;
}

function SubmitButton(props: PropsWithChildren<Props>) {
  let btnAnimationClass = "";
  let textAnimationClass = "";
  let checkmarkAnimationClass = "";
  switch (props.status) {
    case "AuthoringAnimation":
    case "WaitingAnimation":
      btnAnimationClass = "animate-authoring";
      textAnimationClass = "animate-text";
      break;
    case "OKAnimation":
      btnAnimationClass = "animate-login-ok";
      textAnimationClass = "animate-text";
      checkmarkAnimationClass = "checkmark-animation";
      break;
    case "AuthKO":
      btnAnimationClass = "animate-login-ko";
      break;
    default:
      break;
  }

  return (
    <button
      type="submit"
      disabled={
        props.status === "AuthoringAnimation" || props.status === "OKAnimation"
      }
      data-ok-animation-duration={OK_ANIMATION_DURATION}      
      data-auth-animation-duration={AUTH_ANIMATION_DURATION}
      className={`log-submit-btn ${btnAnimationClass} relative w-52 text-white bg-emerald hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
    >
      <span className={`${textAnimationClass}`}>{props.children}</span>
      <svg
        className={`${checkmarkAnimationClass} checkmark`}
        viewBox="0 0 52 52"
      >
        <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </button>
  );
}

export default SubmitButton;
export type { SubmitButtonStatus };
