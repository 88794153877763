import React from "react";
interface IProps {
  height: number;
}
const LogoBiweeByWeenove = (props: IProps) => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 175 151.5"
      height={props.height}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n\t.st0{fill:#698EA3;}\n\t.st1{fill:#86CCD2;}\n\t.st2{fill:none;}\n\t.st3{fill:#808080;}\n\t.st4{font-family:'Exo 2 medium';}\n\t.st5{font-size:17.6595px;}\n",
        }}
      />
      <g>
        <path
          className="st0"
          d="M13.9,69.6h18.7c4.7,0,8.5,1.3,10.9,3.7c1.8,1.8,2.8,4.1,2.8,6.9v0.1c0,5-2.9,7.7-5.9,9.3
		c4.9,1.7,8.3,4.5,8.3,10.1v0.1c0,7.5-6.1,11.4-15.4,11.4H13.9V69.6z M39,81.4c0-3.3-2.6-5.3-7.3-5.3H21.1v11.1h10
		c4.7,0,7.9-1.8,7.9-5.7V81.4z M32.7,93.4H21.1v11.5h12.1c5,0,8.1-2,8.1-5.7V99C41.3,95.5,38.5,93.4,32.7,93.4z"
        />
        <path
          className="st0"
          d="M54.9,68.2h7.7V75h-7.7C54.9,75,54.9,68.2,54.9,68.2z M55.2,79.9h7.2v31.4h-7.2V79.9z"
        />
        <path
          className="st0"
          d="M67.7,80.3h3.8l8.9,25.8l8.5-25.9h2.9l8.5,25.9l8.9-25.8h3.7l-11.1,31.2h-3l-8.5-25.2l-8.6,25.2h-2.9
		L67.7,80.3z"
        />
        <path
          className="st0"
          d="M116.5,80.3h22.4v3.2H120v10.6H137v3.2H120v10.9h19.1v3.2h-22.6V80.3z"
        />
        <path
          className="st0"
          d="M143.3,80.3h22.5v3.2h-18.9v10.6h16.9v3.2h-16.9v10.9H166v3.2h-22.6L143.3,80.3L143.3,80.3z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M106.5,31.2c-6.6-0.2-11.7-1.9-12.6-10.4c-0.8-8.1-7.3-11.7-13.8-11.7c-7.5,0-13.4,6-13.4,13.4
		c0,7.5,4.9,12.8,13.4,13.4C88,36.7,92,42,93.2,46.9c1.7,6.8,7.9,11.2,13.3,11.2c7.5,0,13.9-6,13.4-13.4
		C119.5,37.9,115.1,31.6,106.5,31.2z"
        />
        <circle className="st1" cx="67.4" cy="50.3" r="7.8" />
      </g>
      <rect x={13} y={126} className="st2" width="24.5" height={17} />
      <text transform="matrix(1 0 0 1 12.998 138.3418)" className="st3 st4 st5">
        by
      </text>
      <g>
        <path
          className="st3"
          d="M43.8,138.4l-5.7-15.9l0,0h2h2l0,0l3.1,10l0,0l0,0l2.5-10h1.5h1.5l2.5,10l0,0l0,0l3.1-10l0,0h2h2l0,0
		l-5.7,15.9h-1.2h-1.2l-3-10.4l0,0l0,0l-3,10.4H45H43.8L43.8,138.4z M69.9,138.9c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.4-3.6-2.4-6
		s0.8-4.4,2.4-6c1.6-1.7,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5c1.6,1.7,2.4,3.7,2.4,6c0,0.6,0,1.2-0.1,1.7l0,0H65.6l0,0
		c0.2,0.7,0.6,1.3,1.1,1.9c0.9,1,1.9,1.4,3.3,1.4c0.8,0,1.5-0.2,2.2-0.6s1.2-0.9,1.5-1.5l0,0h4l0,0c-0.5,1.7-1.5,3-2.9,4
		C73.4,138.4,71.8,138.9,69.9,138.9L69.9,138.9z M74.2,129L74.2,129c-0.2-0.8-0.6-1.6-1.2-2.2c-0.9-1-1.9-1.4-3.2-1.4
		c-1.3,0-2.3,0.5-3.2,1.4c-0.6,0.6-0.9,1.4-1.2,2.2l0,0H74.2L74.2,129z M88.2,138.9c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.4-3.6-2.4-6
		s0.8-4.4,2.4-6c1.6-1.7,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5c1.6,1.7,2.4,3.7,2.4,6c0,0.6,0,1.2-0.1,1.7l0,0H84l0,0
		c0.2,0.7,0.6,1.3,1.1,1.9c0.9,1,1.9,1.4,3.3,1.4c0.8,0,1.5-0.2,2.2-0.6c0.7-0.4,1.2-0.9,1.5-1.5l0,0h4l0,0c-0.5,1.7-1.5,3-2.9,4
		C91.7,138.4,90.1,138.9,88.2,138.9L88.2,138.9z M92.6,129L92.6,129c-0.2-0.8-0.6-1.6-1.2-2.2c-0.9-1-1.9-1.4-3.2-1.4
		c-1.3,0-2.3,0.5-3.2,1.4c-0.6,0.6-0.9,1.4-1.2,2.2l0,0H92.6z"
        />
        <path
          className="st3"
          d="M113,126.2c-0.3-0.9-0.7-1.6-1.2-2.3l0,0c-0.1-0.1-0.2-0.2-0.3-0.3c-1.2-1.1-3.1-1.6-5-1.6
		c-1.9,0-3.8,0.6-5,1.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,0.6-1,1.3-1.2,2.3c-0.3,0.9-0.4,1.9-0.4,3.1v9.2h2.4v-8.6l0,0
		c0-0.9,0.1-1.7,0.3-2.4c0.2-0.7,0.5-1.3,0.8-1.7c0.1-0.2,0.3-0.4,0.6-0.6c0.8-0.6,1.8-0.9,2.9-0.9l0,0c1.1,0,2.2,0.3,2.9,0.9
		c0.2,0.1,0.4,0.3,0.6,0.6c0.4,0.5,0.6,1.1,0.8,1.7c0.2,0.7,0.3,1.5,0.3,2.4l0,0v8.6h2.4v-9.2C113.4,128.1,113.3,127.1,113,126.2
		L113,126.2z M118.5,130.4L118.5,130.4L118.5,130.4L118.5,130.4c0-0.9,0.1-1.7,0.4-2.4c0.3-0.7,0.7-1.4,1.3-2
		c0.6-0.6,1.2-1.1,1.9-1.3s1.5-0.5,2.3-0.5c0.8,0,1.6,0.1,2.3,0.5c0.7,0.3,1.3,0.7,1.9,1.3c0.4,0.4,0.7,0.8,0.9,1.3
		c0.1,0.2,0.2,0.5,0.3,0.7c0.3,0.7,0.4,1.6,0.4,2.4l0,0l0,0c0,1.2-0.3,2.4-0.9,3.4c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.1,0.2-0.3,0.4-0.4,0.5l0,0l0,0c-0.6,0.6-1.2,1.1-1.9,1.3c-0.7,0.3-1.5,0.5-2.3,0.5c-0.8,0-1.6-0.1-2.3-0.5
		c-0.7-0.3-1.3-0.7-1.9-1.3c-0.5-0.5-0.8-1.1-1.1-1.7l0,0c0-0.1-0.1-0.2-0.1-0.4l0,0C118.6,132.2,118.5,131.3,118.5,130.4
		L118.5,130.4z M116.2,128.8c-0.1,0.6-0.1,1.1-0.1,1.7c0,0.4,0,0.8,0.1,1.2l0,0.2l0,0l0,0.2l0,0l0,0c0.1,0.4,0.2,0.7,0.3,1.2l0,0
		l0,0l0.1,0.2l0,0l0.1,0.2l0,0l0,0c0.3,0.7,0.7,1.4,1.2,2.1l0,0c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3l0,0l0,0l0,0l0,0
		l0,0l0,0c0.4,0.4,0.8,0.8,1.2,1.1c0.5,0.3,0.9,0.6,1.4,0.8c0.4,0.2,0.8,0.3,1.3,0.4c0.1,0,0.2,0,0.3,0c0.6,0.1,1.1,0.1,1.7,0.1l0,0
		l0,0c0.8,0,1.6-0.1,2.3-0.4c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2l0,0l0,0l0,0c0.3-0.1,0.5-0.3,0.7-0.4
		c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0.3-0.2,0.6-0.5,1-0.8c0,0,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.2-0.2l0,0l0,0l0,0l0,0
		c0.5-0.6,1-1.2,1.3-1.9l0,0c0-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.2-0.4l0,0l0,0l0,0c0.2-0.4,0.3-0.9,0.4-1.3c0-0.1,0-0.2,0-0.3
		c0.1-0.6,0.1-1.1,0.1-1.7l0,0l0,0c0-0.6,0-1.1-0.1-1.6c-0.1-0.5-0.3-1.1-0.5-1.6c-0.3-0.7-0.7-1.4-1.2-2c-0.1-0.1-0.2-0.2-0.3-0.4
		l0,0c-0.1-0.1-0.2-0.2-0.3-0.3l0,0l0,0l0,0c-0.6-0.6-1.2-1.1-1.9-1.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.2l0,0l0,0l0,0
		c-0.4-0.2-0.8-0.3-1.3-0.4c-0.1,0-0.2,0-0.3,0l0,0c-0.6-0.1-1.1-0.1-1.7-0.1l0,0l0,0c-0.8,0-1.6,0.1-2.3,0.4
		c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2l0,0l0,0c-0.5,0.2-1,0.5-1.5,0.8c-0.5,0.3-0.9,0.7-1.3,1.1c-0.6,0.6-1,1.2-1.4,1.9
		c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3-0.2,0.4l0,0l0,0l0,0C116.4,127.8,116.3,128.3,116.2,128.8L116.2,128.8z M139.7,138.5h1.8
		l6.5-16.2h-2.5l-4.8,12.7l-4.6-12.7h-2.5L139.7,138.5L139.7,138.5z M162.2,126.9c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.4h-0.4H152h-0.5
		l0.1-0.4c0.3-1,0.7-1.9,1.3-2.6c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.4l0,0l0,0l0,0c0.5-0.4,1.1-0.7,1.7-0.9
		c0.6-0.2,1.2-0.3,1.9-0.3c0.7,0,1.3,0.1,1.9,0.3c0.3,0.1,0.6,0.2,0.8,0.4l0,0c0.3,0.1,0.6,0.3,0.8,0.5l0,0l0,0l0,0
		C161.4,125.8,161.9,126.3,162.2,126.9L162.2,126.9z M151.5,131.6L151.5,131.6l-0.1-0.4h0.4h13.8v-0.1c0-0.6,0-1.1-0.1-1.6
		s-0.1-1-0.3-1.4l0,0c0-0.2-0.1-0.5-0.2-0.6l0,0c-0.1-0.2-0.1-0.5-0.2-0.6c-0.1-0.3-0.3-0.6-0.4-0.8l-0.1-0.1l0,0l-0.1-0.1l0,0l0,0
		l0,0l0,0c-0.4-0.6-0.9-1.2-1.4-1.6c-0.5-0.5-1.1-0.9-1.7-1.2c-0.5-0.3-1-0.5-1.6-0.6l0,0c-0.1,0-0.2,0-0.3-0.1l0,0
		c-0.6-0.2-1.3-0.2-2.1-0.2l0,0l0,0l0,0c-0.6,0-1.2,0-1.7,0.2l0,0c-0.6,0.1-1.1,0.3-1.6,0.5l0,0c-0.5,0.2-1,0.5-1.4,0.8
		c-0.5,0.3-0.9,0.7-1.3,1.2l0,0c-0.6,0.6-1,1.2-1.3,1.9l0,0c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3-0.2,0.4l0,0l0,0l0,0
		c-0.1,0.4-0.3,0.8-0.4,1.3c0,0.1,0,0.2,0,0.3c-0.1,0.5-0.1,1.1-0.1,1.7l0,0l0,0c0,0.4,0,0.8,0.1,1.2l0,0.2l0,0l0,0.2l0,0l0,0l0,0
		c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.2,0.6,0.3,0.8l0,0c0.3,0.7,0.7,1.4,1.2,2.1l0,0c0.1,0.1,0.2,0.2,0.3,0.4l0,0
		c0.1,0.1,0.2,0.2,0.3,0.3l0,0l0,0l0,0l0,0l0,0l0,0c0.4,0.4,0.8,0.8,1.3,1.1c0.5,0.3,0.9,0.6,1.4,0.8c0.4,0.2,0.8,0.3,1.3,0.4
		c0.1,0,0.2,0,0.3,0c0.6,0.1,1.1,0.1,1.7,0.1l0,0l0,0c0.9,0,1.7-0.1,2.5-0.4c0.8-0.2,1.5-0.6,2.2-1.1c0.7-0.5,1.2-1.1,1.8-1.7
		c0.4-0.6,0.8-1.2,1.2-1.9h-2.7c-0.2,0.4-0.5,0.7-0.8,1.1c-0.3,0.4-0.7,0.6-1.2,0.9l0,0c-0.4,0.2-0.7,0.4-1.2,0.5
		c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1,0.2-1.5,0.2l0,0l0,0c-0.7,0-1.4-0.1-2.1-0.4c-0.6-0.2-1.2-0.6-1.7-1.1l0,0
		c-0.8-0.6-1.3-1.5-1.7-2.4l0,0c-0.1-0.2-0.1-0.4-0.2-0.6C151.6,132.1,151.5,131.8,151.5,131.6z"
        />
      </g>
    </svg>
  );
};

export default LogoBiweeByWeenove;
