import React from "react";
import { useGetLoggedUser } from "../../auth/hooks/useGetLoggedUser";

const Profile = () => {
  const user = useGetLoggedUser();
  return (
    <div>
      <h1>Profile</h1>
      <h2>
        {user.firstName} {user.name}
      </h2>
    </div>
  );
};

export default Profile;
