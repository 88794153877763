//TODO: Copie du fichier index.cjs car je n'arrive pas à jongler entre cjs et esmodules (cjs obligatoire? pour tailwind config)
const colors = {
  white: "#FFFFFF",
  lightBlue: "#6CC3C3",
  darkBlue: "#3D4B66",
  pink: "#FF5F8C",
  emerald: "#58A2A2",
  success: "#54C052",
  successVariant: "#46A345",
  error: "#DC3545",
  errorVariant: "#B72B39",
  warning: "#FD7E14",
  warningVariant: "#E36F0D",
  darkGrey: "#BBBFD4",
  mediumGrey: "#64748b",
  grey: "#DCDFEE",
  lightGrey: "#E8EAF4",
};

export { colors };
