import { Icon } from "@iconify/react";
import homeAnalytics from "@iconify/icons-mdi/home-analytics";

type iconName =
  | "DASHBOARDS_HOME"
  | "USERS"
  | "CONNECTIONS"
  | "DASHBOARDS_FOLDER"
  | "ADMINISTRATION"
  | "DOWNLOAD"
  | "SUBSCRIPTION"
  | "HELP"
  | "BIWEE"
  | "BELL"
  | "SEARCH"
  | "HEART_OUTLINE"
  | "HEART_FILLED"
  | "SHARE_ACTION"
  | "RENAME_ACTION"
  | "DELETE_ACTION"
  | "HIDE_ACTION";

interface IProps {
  iconName: iconName;
  width: number;
  color: string;
}
const IconHelper = (props: IProps) => {
  switch (props.iconName) {
    case "BIWEE":
      return (
        <svg
          version="1.1"
          id="Calque_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={props.width}
          height={props.width}
          x="0px"
          y="0px"
          viewBox="0 0 144 125"
          xmlSpace="preserve"
        >
          <path
            fill={props.color}
            className="st0"
            d="M109,58.1C94.6,57.6,83.5,54,81.7,35.6C80,18,65.8,10.2,51.6,10.2c-16.2,0-29.2,13.1-29.2,29.2
      c0,16.2,10.6,27.8,29.2,29.2C68.8,70,77.4,81.4,80.1,92.2c3.6,14.7,17.2,24.3,28.8,24.3c16.2,0,30.3-13.1,29.2-29.2
      C137.3,72.5,127.7,58.8,109,58.1z"
          />
          <circle
            fill={props.color}
            className="st1"
            cx="24.2"
            cy="99.6"
            r="16.9"
          />
        </svg>
      );
    case "RENAME_ACTION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25Z"
          />
        </svg>
      );
    case "SHARE_ACTION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7c0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81a3 3 0 0 0 3-3a3 3 0 0 0-3-3a3 3 0 0 0-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.15c-.05.21-.08.43-.08.66c0 1.61 1.31 2.91 2.92 2.91c1.61 0 2.92-1.3 2.92-2.91A2.92 2.92 0 0 0 18 16.08Z"
          />
        </svg>
      );
    case "DELETE_ACTION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
          />
        </svg>
      );

    case "HIDE_ACTION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="m19.97 21.385l-3.356-3.356c-1.448.66-3.023.991-4.614.973c-1.64.02-3.263-.334-4.746-1.035a10.073 10.073 0 0 1-3.041-2.282A10.499 10.499 0 0 1 2.1 12.316l-.1-.314l.105-.316a10.786 10.786 0 0 1 3.516-4.651L3 4.414l1.413-1.412l16.969 16.969l-1.41 1.414h-.002ZM7.036 8.451a8.574 8.574 0 0 0-2.919 3.551a8.308 8.308 0 0 0 7.883 5a9.308 9.308 0 0 0 3.087-.5l-1.8-1.8c-.4.196-.84.299-1.287.3a3.019 3.019 0 0 1-3-3c0-.447.103-.888.3-1.29L7.036 8.451Zm12.816 7.161l-1.392-1.391a8.594 8.594 0 0 0 1.423-2.219a8.3 8.3 0 0 0-7.883-5c-.247 0-.495.009-.735.026L9.5 5.261c.822-.176 1.66-.263 2.5-.259c1.64-.02 3.263.334 4.746 1.035c1.15.56 2.181 1.335 3.041 2.282a10.5 10.5 0 0 1 2.113 3.365l.1.318l-.105.316a10.427 10.427 0 0 1-2.042 3.3l-.001-.006Z"
          />
        </svg>
      );

    case "HEART_OUTLINE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z"
          />
        </svg>
      );
    case "HEART_FILLED":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"
          />
        </svg>
      );

    case "BELL":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M10 21h4c0 1.1-.9 2-2 2s-2-.9-2-2m11-2v1H3v-1l2-2v-6c0-3.1 2-5.8 5-6.7V4c0-1.1.9-2 2-2s2 .9 2 2v.3c3 .9 5 3.6 5 6.7v6l2 2m-4-8c0-2.8-2.2-5-5-5s-5 2.2-5 5v7h10v-7Z"
          />
        </svg>
      );

    case "SEARCH":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M9.5 3A6.5 6.5 0 0 1 16 9.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5l-1.5 1.5l-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 9.5 16A6.5 6.5 0 0 1 3 9.5A6.5 6.5 0 0 1 9.5 3m0 2C7 5 5 7 5 9.5S7 14 9.5 14S14 12 14 9.5S12 5 9.5 5Z"
          />
        </svg>
      );

    case "DASHBOARDS_HOME":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M20 19v-8.5a1 1 0 0 0-.4-.8l-7-5.25a1 1 0 0 0-1.2 0l-7 5.25a1 1 0 0 0-.4.8V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1zM8 13v3m4-6v6m4-1v1"
          />
        </svg>
      );
    case "USERS":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M12 5a3.5 3.5 0 0 0-3.5 3.5A3.5 3.5 0 0 0 12 12a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 12 5m0 2a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 12 10a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 7M5.5 8A2.5 2.5 0 0 0 3 10.5c0 .94.53 1.75 1.29 2.18c.36.2.77.32 1.21.32c.44 0 .85-.12 1.21-.32c.37-.21.68-.51.91-.87A5.42 5.42 0 0 1 6.5 8.5v-.28c-.3-.14-.64-.22-1-.22m13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31c.12.19.25.34.4.49a2.482 2.482 0 0 0 1.72.7c.44 0 .85-.12 1.21-.32c.76-.43 1.29-1.24 1.29-2.18A2.5 2.5 0 0 0 18.5 8M12 14c-2.34 0-7 1.17-7 3.5V19h14v-1.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78 14.78 0 15.76 0 17.5V19h3v-1.93c0-1.01.69-1.85 1.71-2.52m14.58 0c1.02.67 1.71 1.51 1.71 2.52V19h3v-1.5c0-1.74-2.78-2.72-4.71-2.95M12 16c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1Z"
          />
        </svg>
      );
    case "CONNECTIONS":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width * 1.1}
          height={props.width * 1.1}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M12 3C7.58 3 4 4.79 4 7v10c0 2.21 3.59 4 8 4s8-1.79 8-4V7c0-2.21-3.58-4-8-4m6 14c0 .5-2.13 2-6 2s-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23s4.39-.45 6-1.23V17m0-4.55c-1.3.95-3.58 1.55-6 1.55s-4.7-.6-6-1.55V9.64c1.47.83 3.61 1.36 6 1.36s4.53-.53 6-1.36v2.81M12 9C8.13 9 6 7.5 6 7s2.13-2 6-2s6 1.5 6 2s-2.13 2-6 2Z"
          />
        </svg>
      );
    case "DASHBOARDS_FOLDER":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm7 4h2v8h-2V9zm4 3h2v5h-2v-5zm-8 2h2v3H7v-3z"
          />
        </svg>
      );
    case "ADMINISTRATION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M12 15.5A3.5 3.5 0 0 1 8.5 12A3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97c0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0 0 14 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1c0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.66Z"
          />
        </svg>
      );
    case "DOWNLOAD":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7l7-7z"
          />
        </svg>
      );
    case "SUBSCRIPTION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M9.5 10.5H12a1 1 0 0 0 0-2h-1V8a1 1 0 0 0-2 0v.55a2.5 2.5 0 0 0 .5 4.95h1a.5.5 0 0 1 0 1H8a1 1 0 0 0 0 2h1v.5a1 1 0 0 0 2 0v-.55a2.5 2.5 0 0 0-.5-4.95h-1a.5.5 0 0 1 0-1ZM21 12h-3V3a1 1 0 0 0-.5-.87a1 1 0 0 0-1 0l-3 1.72l-3-1.72a1 1 0 0 0-1 0l-3 1.72l-3-1.72a1 1 0 0 0-1 0A1 1 0 0 0 2 3v16a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1ZM5 20a1 1 0 0 1-1-1V4.73l2 1.14a1.08 1.08 0 0 0 1 0l3-1.72l3 1.72a1.08 1.08 0 0 0 1 0l2-1.14V19a3 3 0 0 0 .18 1Zm15-1a1 1 0 0 1-2 0v-5h2Z"
          />
        </svg>
      );
    case "HELP":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.width}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill={props.color}
            d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5c0-2.21-1.79-4-4-4z"
          />
        </svg>
      );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M20 19v-8.5a1 1 0 0 0-.4-.8l-7-5.25a1 1 0 0 0-1.2 0l-7 5.25a1 1 0 0 0-.4.8V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1zM8 13v3m4-6v6m4-1v1"
      />
    </svg>
  );
};

export default IconHelper;
export type { iconName };
