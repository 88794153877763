/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * ProtoAPIBackWeb
 * OpenAPI spec version: 1.0
 */
import type {
  User,
  GetLoginParams,
  GetAuthParams,
  Dashboard,
  GetDashboardsParams,
  PatchDashboardsParams
} from '.././model'
import getUsersMutator from '.././utils/axios'
import getLoginMutator from '.././utils/axios'
import getAuthMutator from '.././utils/axios'
import getDashboardsMutator from '.././utils/axios'
import patchDashboardsMutator from '.././utils/axios'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  export const getUsers = (
    
 options?: SecondParameter<typeof getUsersMutator>,) => {
      return getUsersMutator<User[]>(
      {url: `/users`, method: 'get'
    },
      options);
    }
  export const getLogin = (
    params: GetLoginParams,
 options?: SecondParameter<typeof getLoginMutator>,) => {
      return getLoginMutator<User>(
      {url: `/login`, method: 'get',
        params
    },
      options);
    }
  export const getAuth = (
    params: GetAuthParams,
 options?: SecondParameter<typeof getAuthMutator>,) => {
      return getAuthMutator<User>(
      {url: `/auth`, method: 'get',
        params
    },
      options);
    }
  export const getDashboards = (
    params: GetDashboardsParams,
 options?: SecondParameter<typeof getDashboardsMutator>,) => {
      return getDashboardsMutator<Dashboard[]>(
      {url: `/dashboards`, method: 'get',
        params
    },
      options);
    }
  export const patchDashboards = (
    params: PatchDashboardsParams,
 options?: SecondParameter<typeof patchDashboardsMutator>,) => {
      return patchDashboardsMutator<number>(
      {url: `/dashboards`, method: 'patch',
        params
    },
      options);
    }
  