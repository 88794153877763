import { Link } from "react-router-dom";
import { useGetLoggedUser } from "../features/auth/hooks/useGetLoggedUser";
import { useLogOut } from "../features/auth/hooks/useLogOut";
import IconHelper from "../utils/IconHelper";
import { colors } from "../utils/themeColors";

const HorizontalNavBar = () => {
  const user = useGetLoggedUser();
  const logOut = useLogOut();

  const iconSize = 25;
  return (
    <nav
      id="horizontal-nav-bar"
      className="h-14 px-5 flex justify-between items-center overflow-hidden shadow-md bg-white"
    >
      <div id="title" className="font-bold text-darkBlue">
        Dashboards
      </div>
      <div id="navh-search-input" className="w-full flex justify-center px-5">
        <div className="w-full max-w-md relative">
          <span className="absolute top-2 left-3">
            <IconHelper
              iconName="SEARCH"
              width={iconSize}
              color={colors.mediumGrey}
            />
          </span>
          <input
            type="search"
            placeholder="Search in Biwee"
            className="pl-12 pr-4 w-full rounded-full py-2 text-base font-normal text-mediumGrey bg-white bg-clip-padding
          border border-solid border-grey transition ease-in-out m-0 
          focus:text-darkBlue focus:bg-white focus:border-darkGrey focus:outline-none"
          />
        </div>
      </div>
      <div id="navh-links" className="">
        <ul className="flex items-center">
          <li className="ml-2">
            <Link to="/">
              <IconHelper
                iconName="BELL"
                width={iconSize}
                color={colors.mediumGrey}
              />
            </Link>
          </li>
          <li className="ml-4">
            <Link to="/profile">
              <div
                id="profile"
                className="bg-slate-300 rounded-full w-8 h-8 text-sm flex items-center justify-center font-semibold text-darkBlue"
              >
                {getInitials(user.firstName, user.name)}
              </div>
            </Link>
          </li>
          <li>
            <button
              className="text-[9px] w-7 ml-2 text-mediumGrey tracking-tight"
              onClick={() => logOut()}
            >
              logout
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

function getInitials(
  firstName: string | undefined | null,
  lastName: string | undefined | null
) {
  return firstName?.charAt(0) ?? "" + lastName?.charAt(0) ?? "";
}

export default HorizontalNavBar;
