import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMachine } from "@xstate/react";
import { useEffect, PropsWithChildren, useRef } from "react";
import { Transition } from "react-transition-group";
import { CSSTransition } from "react-transition-group";
import FullPageSpinner from "../../../sharedComponents/FullPageSpinner";
import { getAuth } from "../../../proto-api-back-web/proto-api-back-web";
import authStateMachine, { Credentials } from "../AuthStateMachine";
import Login from "./Login";
import UserAuthContext from "../hooks/UserAuthContext";
import { inspect } from "@xstate/inspect";
import { SubmitButtonStatus } from "./SubmitButton";

type StateStatus =
  | "CheckPreviousUser"
  | "Logged"
  | "Login"
  | "Login.AuthoringAnimation"
  | "Login.OKAnimation"
  | "Login.UpdatingForm"
  | "Login.WaitingAnimation"
  | "ReturningUserToAuth";

// inspect({
//   // options
//   // url: 'https://stately.ai/viz?inspect', // (default)
//   iframe: false, // open in new window
// });

export const AuthProvider = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    import("../../dashboardViewer/DashboardViewer");
  }, []);
  const [state, send] = useMachine(authStateMachine);
  const email = state.context.credentialsToCheck.email as string;

  const loginNodeRef = useRef(null);

  const queryClient = useQueryClient();
  const timeBeforeRefreshAuth = 1000 * 60 * 60 * 1;

  const { data, error, isError, isSuccess } = useQuery(["user", email], {
    queryFn: () => getAuth({ email: email }),
    enabled: !!email,
    staleTime: timeBeforeRefreshAuth,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isError) {
      send({
        type: "UserNotAuthorized",
        error: error,
      });
    }

    if (isSuccess) {
      const user = data ? data : {};
      send({
        type: "UserAuthorizedSuccess",
        data: user,
      });
    }
  }, [data, error, send, isError, isSuccess]);

  return (
    <>
      <CSSTransition
        nodeRef={loginNodeRef}
        in={state.matches("LoginManagement.Login")}
        timeout={200}
        classNames="login"
        unmountOnExit
        appear
      >
        <div ref={loginNodeRef}>
          <Login
            HandleLogin={HandleLogin}
            status={GetSubmitAnimationStatus()}
          />
        </div>
      </CSSTransition>

      {state.matches("LoginManagement.Logged") && (
        <UserAuthContext.Provider
          value={{
            user: state.context.loggedUser!,
            logOut: HandleLogOut,
          }}
        >
          {children}
        </UserAuthContext.Provider>
      )}
      {state.matches("LoginManagement.ReturningUserToAuth") && (
        <FullPageSpinner />
      )}
    </>
  );

  function GetSubmitAnimationStatus(): SubmitButtonStatus {
    if (state.matches("LoginManagement.Login.AuthoringAnimation")) {
      return "AuthoringAnimation";
    } else if (
      state.matches("LoginManagement.Login.OKAnimation") ||
      state.matches("LoginManagement.Logged")
    ) {
      return "OKAnimation";
    } else if (state.matches("LoginManagement.Login.WaitingAnimation")) {
      return "WaitingAnimation";
    }
    return "Idle";
  }

  function HandleLogin(credentials: Credentials) {
    send({
      type: "Submit",
      email: credentials.email!,
      password: credentials.password!,
    });
  }

  function HandleLogOut() {
    send({ type: "Logout", queryClient: queryClient! });
  }
};

export type { StateStatus };
