import { QueryClient } from "@tanstack/react-query";
import { createMachine, assign } from "xstate";
import { User } from "../../model/user";

const  MEDIUM_ANIMATION_DURATION = 300;
const  SHORT_ANIMATION_DURATION = 150;

export {MEDIUM_ANIMATION_DURATION as OK_ANIMATION_DURATION, SHORT_ANIMATION_DURATION as AUTH_ANIMATION_DURATION}

interface Credentials {
  email: string;
  password?: string;
  jsonToken?: string;
}

interface Context {
  credentialsToCheck: Credentials;
  loggedUser?: User | null;
}

const intialContext: Context = {
  credentialsToCheck: {
    email: "",
  },
  loggedUser: null,
};

type AuthEvent =
  | { type: "Submit"; email: string; password: string }
  | { type: "UserAuthorizedSuccess"; data: User }
  | { type: "UserNotAuthorized"; error?: unknown }
  | { type: "Logout"; queryClient: QueryClient };

const authStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEECuAXAFgBQE4HsA3ASwjFwDoAZfKYgOwFkBDe5mAWzHvWtpggBiGlHwYA2gAYAuolAAHfLGLpi+enJAAPRADYArACYKugIwB2C4d0BOO-oDMpgDQgAnolOTJ+k9d0ALEG6AByShpI2AL5RrmhYeESk5Hx0TKzsYFw8FADCmGAAxgDWeGAkYrAAqrDkglKySCCKyqrqmjoIIQEUNiH++oGGjpIOhq4eCA7m5ib6jrqSuk6G5vrRsSDxOAQkZJQiDCxsnNy8+UWluOVqqNW1uPWmjQpKKmoaTZ2GAZK9ITZ9OZ+gFzJIwQ4Joh5g4KA54Q4Ag59D4QusAjE4hgdkl9qkjhlTjlDvQKFV5BBmKp6FAAGL4XAcQQAZVQACMOCoGpoWu92l9EH0QhRJACfkjzEjQeN3NDRRR+g5Ast9GibKZTJittjEnsUiTjplsrwSRQAOrMd405D0YgcKkfeoyHlvNqfUCdLwOYW6QyAvqmIGrRxQhDmCIi9bwkLA4HIhxa7a65IHWgEk5ZM740kAeQA0ja7Q71IItLB0FSwBRmAAzdDkAAU+YA+sgAHIASUYyAAKh2c23mwARACiVGQAE0AJSCJO7FPZw1Ek1p3MF232t3cpq8t0dTyrYWBoLmBw2cw2Bw+cyhrw+XrecEagLzAIGRM6+d4g2EzPE1cUNsDIMFAhabo6ZYVvW1Z1o2yBVD2AASradt2fYDsOY6TjOc64vqq5Ln+K5pIB2LAdaG7FvQ26vK0Hz7ggL6wgEhiIiCyrgvCoa6IEFCsSEXhGJeqreh+CRfvhaSEcaFAAEpgOgqC4LaNI1OQPb4NsghqbgQG4MQABekCsoUhRwLANHNK69ECggAC0pgBDYJgxvoz5BJEpghLekheXCCJIiiqromJOJ6qmUm-jJ8mKcpIE6RpWk6W2+DoHphmQJZu42R6iCGI5JjIr8gQ2AEjlLDKkzqj0dh2LYAQgvlGxYuJeGUNspFYORUDaQ86VGRAJlmbAFnOju1n8rlYZ-Cs+ihnZhg-L0tX1Y1XkhKFyZ4h16U0r15ApWlZH6QNWUTe62ieL5PQqoi+gvgC3QBPNGqzEVkhlWCqxeItMSbPQ+BkPATS4eFLp0ZNl32T8vihGs7m-Oq3myvZZ7OYC-TnuGpU2L9mygwuP4Zsa4N8hdnQOeeLnw45HlI7eV5wmVIS2BEqo2Lop6bRJEXpkaWYiAIpN7rZHOmCKpjLCzF6+j4Ng+R9FC-L5bGmKsTnNdqrXhYuUVZhcJRlBUdw6cLOVQw58pBrjrMXl9kIo-lsz9F68J+ki63c21uvEwLq5m5DnS-L4LFsT8HGng7kwseLiJBP0rFBesXs60T-P-iR5KUtSdIMhwAfk4gli6Er3jnos3gzLooaOOLZi+eYZjqkYfop4TBF6xnDDmpaOdgVRBcMXbCqBt0YINUiZjcXYFCS04kqREsdiGG334d77Xdrv3Iu0WTDFq76FBgpxTk8SEYSVUXS1vrYkuSzGZ6avjn7e2ny7Zp1mDddv5vZYHB4s16BYFE4ZFifWrijdYfxJRon6AsNEoRV6ST5u-GKSkVJQASppbEg9bKW2FNbQMks5ac1DE5WY4ZQS-CCIGU8PEkHtRweNCGhcECLThGrOaKM7IrCVheUw8JFjrCBKMBhn9P7dVwVNcMxgzBvgiN0UU0wo6IDsgCCg8xRhhHWP0D6uM-pRCAA */
  createMachine(
    {
      predictableActionArguments: true,
      tsTypes: {} as import("./AuthStateMachine.typegen").Typegen0,
      schema: {
        context: {} as Context,
        events: {} as AuthEvent,
      },

      id: "AuthProvider",
      context: intialContext,

      states: {
        LoginManagement: {
          states: {
            Logged: {
              on: {
                Logout: {
                  target: "Login",
                  actions: "Logout",
                },
              },
            },

            CheckPreviousUser: {
              entry: ["CheckLocalStorageUser"],

              always: [
                {
                  target: "ReturningUserToAuth",
                  cond: "IsReturningUser",
                },
                "Login",
              ],
            },

            Login: {
              states: {
                UpdatingForm: {
                  on: {
                    Submit: {
                      target: "AuthoringAnimation",
                      actions: "UpdateCredentialsToCheck",
                    },
                  },
                },

                WaitingAnimation: {
                  always: {
                    target: "OKAnimation",
                    cond: "AuthIsOk",
                  },
                },

                OKAnimation: {
                  after: {
                    OK_ANIMATION_DELAY: "#AuthProvider.LoginManagement.Logged",
                  },
                },

                AuthoringAnimation: {
                  after: {
                    AUTH_ANIMATION_DELAY: "WaitingAnimation",
                  },
                },
              },

              initial: "UpdatingForm",
            },

            ReturningUserToAuth: {
              on: {
                UserAuthorizedSuccess: {
                  target: "Logged",
                  actions: "UpdateContextOnAuthSuccess",
                },

                UserNotAuthorized: {
                  target: "Login",
                  actions: "UpdateContextOnAuthError",
                },
              },
            },
          },

          initial: "CheckPreviousUser",
        },

        Auth: {
          states: {
            Authoring: {
              on: {
                UserAuthorizedSuccess: {
                  target: "Authoring",
                  actions: "UpdateContextOnAuthSuccess",
                  internal: true,
                },

                UserNotAuthorized: {
                  target: "Authoring",
                  internal: true,
                  actions: "UpdateContextOnAuthError",
                },
              },
            },
          },

          initial: "Authoring",
        },
      },

      initial: "",
      type: "parallel",
    },

    {
      delays: {
        OK_ANIMATION_DELAY: MEDIUM_ANIMATION_DURATION + 200,
        AUTH_ANIMATION_DELAY: SHORT_ANIMATION_DURATION, 
      },
      guards: {
        AuthIsOk: (context) => {
          return context.loggedUser !== null;
        },
        IsReturningUser: (context) => {
          return context.credentialsToCheck.email !== "";
        },
      },
      actions: {
        UpdateContextOnAuthSuccess: assign({
          loggedUser: (context, event) => {
            console.log("UpdateContextOnAuthSuccess Action/ event : ", event);
            localStorage.setItem("email", context.credentialsToCheck.email!);
            return event.data;
          },
        }),

        UpdateContextOnAuthError: assign({
          loggedUser: (context, event) => {
            return null;
          },
        }),
        UpdateCredentialsToCheck: assign({
          credentialsToCheck: (_, event) => {
            return {
              email: event.email,
              password: event.password,
            } as Credentials;
          },
        }),
        CheckLocalStorageUser: assign({
          credentialsToCheck: () => {
            const localStorageEmail = localStorage.getItem("email") ?? "";
            const localStorageToken = localStorage.getItem("token") ?? "";
            return {
              email: localStorageEmail,
              jsonToken: localStorageToken,
            } as Credentials;
          },
        }),
        Logout: assign((_, event) => {
          console.log("Logout Action");
          localStorage.removeItem("email");
          localStorage.removeItem("token");
          event.queryClient.removeQueries({ queryKey: ["user"] });

          return intialContext;
        }),
      },
    }
  );

export default authStateMachine;
export type { Credentials };
