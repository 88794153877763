import React from "react";
import IconHelper from "../../utils/IconHelper";
import { colors } from "../../utils/themeColors";

type Props = {
  isPinned: boolean;
  handlePinClick: () => void;
};

const HeartPin = (props: Props) => {
  return (
    <button
      onClick={() => props.handlePinClick()}
      className="pr-6 flex items-center justify-center"
    >
      <IconHelper
        iconName={props.isPinned ? "HEART_FILLED" : "HEART_OUTLINE"}
        width={15}
        color={colors.pink}
      />
    </button>
  );
};

export default HeartPin;
