import { Link } from "react-router-dom";
import IconHelper from "../utils/IconHelper";
import { MenuItem } from "./types";

type Props = {
  topMenuItems: MenuItem[];
  bottomMenuItems: MenuItem[];
};

const VerticalNavBar: React.FC<Props> = ({ topMenuItems, bottomMenuItems }) => {
  const iconSize = 35;

  return (
    <nav className="flex h-screen min-h-[600px] w-[80px] relative flex-col items-center bg-darkBlue pt-2">
      <Link to="/">
        <IconHelper iconName="BIWEE" width={48} color="white" />
      </Link>

      <div className="div"></div>
      <ul className="mt-8">
        {topMenuItems.map((menuItem) => (
          <li key={menuItem.title} className="flex flex-col items-center mb-4">
            <Link to={menuItem.url}>
              <div className="flex flex-col items-center">
                <IconHelper
                  iconName={menuItem.iconName}
                  width={iconSize}
                  color="white"
                />
                <span className="text-[11px] text-white font-light mt-1">
                  {menuItem.title}
                </span>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <ul className="absolute bottom-0">
        {bottomMenuItems.map((menuItem) => (
          <li key={menuItem.title} className="flex flex-col items-center mb-2">
            <Link to={menuItem.url}>
              <div className="flex flex-col items-center">
                <IconHelper
                  iconName={menuItem.iconName}
                  width={25}
                  color="white"
                />
                <span className="text-[11px] text-white font-light mt-1">
                  {menuItem.title}
                </span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default VerticalNavBar;
