import React from "react";

type Props = {
  message?: string;
};

const ErrorPage = (props: Props) => {
  return <div>404 Cette page n'existe pas</div>;
};

export default ErrorPage;
