import { User } from "../../../model";
import { createContext } from "react";

interface AppContext {
  user: User;
  logOut: () => void;
}

const UserAuthContext = createContext<AppContext>({
  user: {},
  logOut: () => {},
});
export default UserAuthContext;
