import { useQuery } from "@tanstack/react-query";
import { Dashboard } from "../../../model";
import { getDashboards } from "../../../proto-api-back-web/proto-api-back-web";



function useDashboards(email: string): Dashboard[] {
  const fallback: Dashboard[] = [];
  const { data: dashboards = fallback } = useQuery(["dashboards", email], () =>
    getDashboards({ email })
  );

  return dashboards;
}

export default useDashboards;
