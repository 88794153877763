import { QueryClient, useMutation } from "@tanstack/react-query";
import { Dashboard, PatchDashboardsParams } from "../../../model";
import { patchDashboards } from "../../../proto-api-back-web/proto-api-back-web";

function useDashboardPinMutation(queryClient: QueryClient, userEmail: string) {
  const dashboardsQueryKey = ["dashboards", userEmail];

  return useMutation({
    mutationFn: (params: PatchDashboardsParams) => {
      return patchDashboards(params);
    },
    onMutate: async (params: PatchDashboardsParams) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: dashboardsQueryKey });

      // Snapshot the previous value
      const previousDashboards: Dashboard[] | undefined =
        queryClient.getQueryData<Dashboard[]>(dashboardsQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Dashboard[]>(
        ["dashboards", userEmail],
        previousDashboards?.map((dashboard) =>
          dashboard.spotID === params.spotID
            ? { ...dashboard, isPinned: params.isFavorite }
            : dashboard
        )
      );

      // Return a context object with the snapshotted value
      return { previousDashboards };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newDashboards, context) => {
      queryClient.setQueryData(dashboardsQueryKey, context?.previousDashboards);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: dashboardsQueryKey });
    },
  });
}

export default useDashboardPinMutation;
