import { Row } from "@tanstack/react-table";
import React from "react";
import { Dashboard } from "../../model";
import IconHelper from "../../utils/IconHelper";
import { colors } from "../../utils/themeColors";

interface DashboardRowActionsProps {
  row: Row<Dashboard>;
}

function DashboardRowActions(props: DashboardRowActionsProps) {
  const actionIconSize = 14;
  const actionIconColor = colors.mediumGrey;
  return (
    <div className="flex">
      <a href="#" className="pr-1">
        <IconHelper
          iconName="RENAME_ACTION"
          width={actionIconSize}
          color={actionIconColor}
        />
      </a>
      <a href="#" className="pr-1">
        <IconHelper
          iconName="SHARE_ACTION"
          width={actionIconSize}
          color={actionIconColor}
        />
      </a>
      <a href="#" className="pr-1">
        <IconHelper
          iconName="DELETE_ACTION"
          width={actionIconSize}
          color={actionIconColor}
        />
      </a>
      <a href="#" className="pr-1">
        <IconHelper
          iconName="HIDE_ACTION"
          width={actionIconSize}
          color={actionIconColor}
        />
      </a>
    </div>
  );
}

export default DashboardRowActions;
